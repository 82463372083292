import React from 'react'

export default function Logo() {
    return (
        <div>
            <svg width="207" height="90" viewBox="0 0 207 90" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M99.2512 25.5081V28.3063C99.2512 29.7055 98.6372 30.2091 97.6605 30.2091C96.6837 30.2091 96.0698 29.7055 96.0698 28.3063V17.729C96.0698 16.3299 96.6837 15.7982 97.6605 15.7982C98.6372 15.7982 99.2512 16.3299 99.2512 17.729V19.8277H102.153V17.9249C102.153 14.7909 100.591 13 97.5767 13C94.5628 13 93 14.7909 93 17.9249V28.1105C93 31.2445 94.5628 33.0353 97.5767 33.0353C100.591 33.0353 102.153 31.2445 102.153 28.1105V25.5081H99.2512Z" fill="black"/>
<path d="M107.923 24.4168H111.411V32.8115H114.537V13.2239H111.411V21.6186H107.923V13.2239H104.853V32.8115H107.923V24.4168Z" fill="black"/>
<path d="M120.67 16.0221H125.972V13.2239H117.6V32.8115H125.972V30.0133H120.67V24.2769H124.884V21.4786H120.67V16.0221Z" fill="black"/>
<path d="M128.321 17.9249C128.321 23.5214 134.321 24.2769 134.321 28.3063C134.321 29.7055 133.707 30.2091 132.73 30.2091C131.753 30.2091 131.139 29.7055 131.139 28.3063V26.9072H128.237V28.1105C128.237 31.2445 129.8 33.0353 132.814 33.0353C135.828 33.0353 137.391 31.2445 137.391 28.1105C137.391 22.514 131.391 21.7585 131.391 17.729C131.391 16.3299 131.949 15.7982 132.925 15.7982C133.902 15.7982 134.46 16.3299 134.46 17.729V18.5405H137.363V17.9249C137.363 14.7909 135.828 13 132.842 13C129.856 13 128.321 14.7909 128.321 17.9249Z" fill="black"/>
<path d="M139.596 17.9249C139.596 23.5214 145.596 24.2769 145.596 28.3063C145.596 29.7055 144.982 30.2091 144.005 30.2091C143.028 30.2091 142.414 29.7055 142.414 28.3063V26.9072H139.512V28.1105C139.512 31.2445 141.075 33.0353 144.089 33.0353C147.103 33.0353 148.666 31.2445 148.666 28.1105C148.666 22.514 142.666 21.7585 142.666 17.729C142.666 16.3299 143.224 15.7982 144.2 15.7982C145.177 15.7982 145.735 16.3299 145.735 17.729V18.5405H148.638V17.9249C148.638 14.7909 147.103 13 144.117 13C141.131 13 139.596 14.7909 139.596 17.9249Z" fill="black"/>
<path d="M102.795 60.7938C102.488 60.0663 102.46 59.3667 102.46 58.4153V55.3932C102.46 53.3505 101.958 51.8675 100.423 51.2239C101.791 50.5803 102.433 49.2651 102.433 47.2504V45.7113C102.433 42.6892 101.065 41.2062 97.8837 41.2062H93.2512V60.7938H96.3209V52.8189H97.3814C98.7767 52.8189 99.3907 53.4904 99.3907 55.3093V58.3873C99.3907 59.9823 99.5023 60.2901 99.6698 60.7938H102.795ZM97.8 44.0044C98.8884 44.0044 99.3628 44.62 99.3628 46.0191V47.9499C99.3628 49.5169 98.6651 50.0206 97.5209 50.0206H96.3209V44.0044H97.8Z" fill="black"/>
<path d="M112.126 41.2062H107.633L104.507 60.7938H107.326L107.856 57.2401H111.624L112.154 60.7938H115.251L112.126 41.2062ZM109.726 44.676L111.205 54.5817H108.247L109.726 44.676Z" fill="black"/>
<path d="M115.562 44.0044H118.772V60.7938H121.841V44.0044H125.051V41.2062H115.562V44.0044Z" fill="black"/>
<path d="M127.24 60.7938H130.31V41.2062H127.24V60.7938Z" fill="black"/>
<path d="M139.949 60.7938H143.103V41.2062H140.368V52.9308L137.215 41.2062H133.363V60.7938H136.126V46.6068L139.949 60.7938Z" fill="black"/>
<path d="M150.743 52.6789H152.138V56.2887C152.138 57.6878 151.524 58.1915 150.547 58.1915C149.571 58.1915 148.957 57.6878 148.957 56.2887V45.7113C148.957 44.3122 149.571 43.7806 150.547 43.7806C151.524 43.7806 152.138 44.3122 152.138 45.7113V47.81H155.04V45.9072C155.04 42.7732 153.478 40.9823 150.464 40.9823C147.45 40.9823 145.887 42.7732 145.887 45.9072V56.0928C145.887 59.2268 147.45 61.0177 150.464 61.0177C153.478 61.0177 155.04 59.2268 155.04 56.0928V49.8807H150.743V52.6789Z" fill="black"/>
<path d="M99.2512 81.4728V84.271C99.2512 85.6701 98.6372 86.1738 97.6605 86.1738C96.6837 86.1738 96.0698 85.6701 96.0698 84.271V73.6937C96.0698 72.2946 96.6837 71.7629 97.6605 71.7629C98.6372 71.7629 99.2512 72.2946 99.2512 73.6937V75.7923H102.153V73.8895C102.153 70.7555 100.591 68.9647 97.5767 68.9647C94.5628 68.9647 93 70.7555 93 73.8895V84.0751C93 87.2091 94.5628 89 97.5767 89C100.591 89 102.153 87.2091 102.153 84.0751V81.4728H99.2512Z" fill="black"/>
<path d="M107.672 73.6937C107.672 72.2946 108.286 71.7629 109.263 71.7629C110.239 71.7629 110.853 72.2946 110.853 73.6937V84.271C110.853 85.6701 110.239 86.2018 109.263 86.2018C108.286 86.2018 107.672 85.6701 107.672 84.271V73.6937ZM104.602 84.0751C104.602 87.2091 106.249 89 109.263 89C112.276 89 113.923 87.2091 113.923 84.0751V73.8895C113.923 70.7555 112.276 68.9647 109.263 68.9647C106.249 68.9647 104.602 70.7555 104.602 73.8895V84.0751Z" fill="black"/>
<path d="M120.997 69.1885H116.727V88.7761H119.406V74.9249L121.695 88.7761H124.597L126.718 74.729V88.7761H129.62V69.1885H125.35L123.257 83.0957L120.997 69.1885Z" fill="black"/>
<path d="M137.211 69.1885H132.69V88.7761H135.76V81.4168H137.211C140.281 81.4168 141.788 79.7099 141.788 76.5759V74.0295C141.788 70.8954 140.281 69.1885 137.211 69.1885ZM137.211 71.9867C138.188 71.9867 138.718 72.4345 138.718 73.8336V76.7717C138.718 78.1708 138.188 78.6186 137.211 78.6186H135.76V71.9867H137.211Z" fill="black"/>
<path d="M150.502 69.1885H146.009L142.884 88.7761H145.702L146.232 85.2224H150L150.53 88.7761H153.628L150.502 69.1885ZM148.102 72.6583L149.581 82.5641H146.623L148.102 72.6583Z" fill="black"/>
<path d="M165.439 88.7761C165.132 88.0486 165.104 87.349 165.104 86.3976V83.3755C165.104 81.3328 164.602 79.8498 163.067 79.2062C164.434 78.5626 165.076 77.2474 165.076 75.2327V73.6937C165.076 70.6716 163.709 69.1885 160.527 69.1885H155.895V88.7761H158.964V80.8012H160.025C161.42 80.8012 162.034 81.4728 162.034 83.2916V86.3697C162.034 87.9647 162.146 88.2725 162.313 88.7761H165.439ZM160.443 71.9867C161.532 71.9867 162.006 72.6024 162.006 74.0015V75.9323C162.006 77.4993 161.309 78.0029 160.164 78.0029H158.964V71.9867H160.443Z" fill="black"/>
<path d="M167.96 88.7761H171.03V69.1885H167.96V88.7761Z" fill="black"/>
<path d="M173.721 73.8895C173.721 79.486 179.721 80.2415 179.721 84.271C179.721 85.6701 179.107 86.1738 178.13 86.1738C177.153 86.1738 176.539 85.6701 176.539 84.271V82.8719H173.637V84.0751C173.637 87.2091 175.2 89 178.214 89C181.228 89 182.791 87.2091 182.791 84.0751C182.791 78.4786 176.791 77.7231 176.791 73.6937C176.791 72.2946 177.349 71.7629 178.325 71.7629C179.302 71.7629 179.86 72.2946 179.86 73.6937V74.5052H182.763V73.8895C182.763 70.7555 181.228 68.9647 178.242 68.9647C175.256 68.9647 173.721 70.7555 173.721 73.8895Z" fill="black"/>
<path d="M188.205 73.6937C188.205 72.2946 188.819 71.7629 189.796 71.7629C190.773 71.7629 191.387 72.2946 191.387 73.6937V84.271C191.387 85.6701 190.773 86.2018 189.796 86.2018C188.819 86.2018 188.205 85.6701 188.205 84.271V73.6937ZM185.135 84.0751C185.135 87.2091 186.782 89 189.796 89C192.81 89 194.456 87.2091 194.456 84.0751V73.8895C194.456 70.7555 192.81 68.9647 189.796 68.9647C186.782 68.9647 185.135 70.7555 185.135 73.8895V84.0751Z" fill="black"/>
<path d="M203.847 88.7761H207V69.1885H204.265V80.9131L201.112 69.1885H197.26V88.7761H200.023V74.5891L203.847 88.7761Z" fill="black"/>
<path d="M22.2645 32.3784H11.7455V35.535H22.2645V32.3784Z" fill="#414141"/>
<path d="M23.471 35.535H10.539C9.98285 35.535 9.53198 35.9859 9.53198 36.5421C9.53198 37.0984 9.98285 37.5493 10.539 37.5493H23.471C24.0272 37.5493 24.478 37.0984 24.478 36.5421C24.478 35.9859 24.0272 35.535 23.471 35.535Z" fill="#231E20"/>
<path d="M24.2388 37.5493H9.76135C8.93535 37.5493 8.26575 38.22 8.26575 39.0474C8.26575 39.8749 8.93535 40.5456 9.76135 40.5456H24.2388C25.0648 40.5456 25.7344 39.8749 25.7344 39.0474C25.7344 38.22 25.0648 37.5493 24.2388 37.5493Z" fill="#414141"/>
<path d="M1.77478 76.281C1.77478 75.6998 1.88887 75.1244 2.11046 74.5875C2.33206 74.0507 2.65685 73.5631 3.06621 73.1526C3.47557 72.7421 3.96146 72.4167 4.49609 72.1952C5.03071 71.9737 5.60353 71.8604 6.1818 71.8617H27.8182C28.3964 71.8604 28.9693 71.9737 29.504 72.1952C30.0386 72.4167 30.5245 72.7421 30.9338 73.1526C31.3432 73.5631 31.668 74.0507 31.8896 74.5875C32.1112 75.1244 32.2252 75.6998 32.2252 76.281H1.77478Z" fill="#414141"/>
<path d="M32.1755 76.2811H1.82471C1.10334 76.2811 0.518555 76.8688 0.518555 77.5938V83.2959C0.518555 84.0209 1.10334 84.6086 1.82471 84.6086H32.1755C32.8968 84.6086 33.4816 84.0209 33.4816 83.2959V77.5938C33.4816 76.8688 32.8968 76.2811 32.1755 76.2811Z" fill="#231E20"/>
<path d="M32.2252 84.6086H1.77478V87.0438H32.2252V84.6086Z" fill="#414141"/>
<path d="M33.4117 87.0438H0.58827C0.263377 87.0438 0 87.3085 0 87.635V89.4088C0 89.7353 0.263377 90 0.58827 90H33.4117C33.7366 90 34 89.7353 34 89.4088V87.635C34 87.3085 33.7366 87.0438 33.4117 87.0438Z" fill="#231E20"/>
<path d="M22.9425 40.5556H11.0674L7.46802 71.8517H26.532L22.9425 40.5556Z" fill="#231E20"/>
<path d="M20.0809 13.9896C19.999 13.4367 19.7707 12.9161 19.4198 12.4824C19.069 12.0487 18.6085 11.7177 18.0867 11.5243V5.45151H21.2873V3.20678H18.0867V0H15.8533V3.20678H12.6528V5.45151H15.8533V11.5243C15.3337 11.7214 14.8755 12.0534 14.5252 12.4864C14.1749 12.9195 13.945 13.4381 13.8592 13.9896C11.9905 14.2614 10.1601 14.7524 8.40523 15.4526C8.17169 15.5487 7.98049 15.7263 7.8669 15.9528C7.75331 16.1793 7.72499 16.4394 7.78706 16.6852L11.6756 32.3884H22.2844L26.173 16.6852C26.2468 16.4393 26.2237 16.1743 26.1087 15.9449C25.9936 15.7155 25.7954 15.5393 25.5548 15.4526C23.7936 14.7509 21.9563 14.2598 20.0809 13.9896Z" fill="#231E20"/>
<path d="M65.2545 32.3784H54.7355V35.535H65.2545V32.3784Z" fill="#E8E7EA"/>
<path d="M66.461 35.535H53.529C52.9728 35.535 52.522 35.9859 52.522 36.5421C52.522 37.0984 52.9728 37.5493 53.529 37.5493H66.461C67.0171 37.5493 67.468 37.0984 67.468 36.5421C67.468 35.9859 67.0171 35.535 66.461 35.535Z" fill="#D1D2D4"/>
<path d="M67.2387 37.5493H52.7612C51.9352 37.5493 51.2656 38.22 51.2656 39.0474C51.2656 39.8749 51.9352 40.5456 52.7612 40.5456H67.2387C68.0646 40.5456 68.7343 39.8749 68.7343 39.0474C68.7343 38.22 68.0646 37.5493 67.2387 37.5493Z" fill="#E8E7EA"/>
<path d="M44.7548 76.281C44.7535 75.7003 44.8663 75.1251 45.0868 74.5883C45.3073 74.0515 45.6311 73.5638 46.0397 73.1532C46.4483 72.7425 46.9335 72.417 47.4676 72.1954C48.0017 71.9738 48.574 71.8604 49.1518 71.8617H70.7982C71.3764 71.8604 71.9493 71.9737 72.4839 72.1952C73.0186 72.4167 73.5045 72.7421 73.9138 73.1526C74.3232 73.5631 74.648 74.0507 74.8696 74.5875C75.0912 75.1244 75.2052 75.6998 75.2052 76.281H44.7548Z" fill="#E8E7EA"/>
<path d="M75.1753 76.2811H44.8246C44.1032 76.2811 43.5184 76.8688 43.5184 77.5938V83.2959C43.5184 84.0209 44.1032 84.6086 44.8246 84.6086H75.1753C75.8967 84.6086 76.4815 84.0209 76.4815 83.2959V77.5938C76.4815 76.8688 75.8967 76.2811 75.1753 76.2811Z" fill="#D1D2D4"/>
<path d="M75.2252 84.6086H44.7748V87.0438H75.2252V84.6086Z" fill="#E8E7EA"/>
<path d="M76.4117 87.0438H43.5883C43.2634 87.0438 43 87.3085 43 87.635V89.4088C43 89.7353 43.2634 90 43.5883 90H76.4117C76.7366 90 77 89.7353 77 89.4088V87.635C77 87.3085 76.7366 87.0438 76.4117 87.0438Z" fill="#D1D2D4"/>
<path d="M65.9325 40.5556H54.0575L50.468 71.8517H69.5319L65.9325 40.5556Z" fill="#D1D2D4"/>
<path d="M63.0809 13.9896C62.995 13.4381 62.7652 12.9195 62.4149 12.4864C62.0646 12.0534 61.6063 11.7214 61.0868 11.5243V5.45151H64.2874V3.20678H61.0868V0H58.8533V3.20678H55.7225V5.45151H58.9331V11.5243C58.4129 11.7205 57.9542 12.0522 57.6037 12.4854C57.2533 12.9186 57.0238 13.4378 56.9389 13.9896C55.0738 14.2625 53.2468 14.7535 51.495 15.4526C51.2544 15.5393 51.0561 15.7155 50.9411 15.9449C50.826 16.1743 50.803 16.4393 50.8768 16.6852L54.7653 32.3884H65.2844L69.1829 16.6852C69.2429 16.4394 69.2135 16.1802 69.1002 15.9542C68.9868 15.7283 68.797 15.5504 68.5648 15.4526C66.7999 14.7516 64.9594 14.2605 63.0809 13.9896Z" fill="#D1D2D4"/>
<rect x="38" width="1" height="90" fill="#E8E7EA"/>
</svg>
        </div>
    )
}
