import React from 'react'
import Updates from './Updates'

export default function About() {

    

    return (
        <div id='about'>
            <h2>Here's the Latest</h2>
                <Updates />
        </div>
    )
}
